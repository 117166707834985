// extracted by mini-css-extract-plugin
export var alignLeft = "t_pL d_fn d_bF d_dt";
export var alignCenter = "t_bN d_fp d_bC d_dv";
export var alignRight = "t_pM d_fq d_bG d_dw";
export var alignColumnLeft = "t_qy d_fr d_bM d_dt";
export var alignColumnCenter = "t_qz d_fs d_bN d_dv";
export var alignColumnRight = "t_qB d_ft d_bP d_dw";
export var milestonesContainer = "t_rz d_dV";
export var milestonesContainerFull = "t_rB d_dS";
export var milestonesSubtitle = "t_mg d_mg d_cr";
export var milestonesComponentWrapper = "t_mc d_mc d_ct";
export var compContentWrapper = "t_qF d_md d_by d_bH d_bL d_bD d_c6 d_G";
export var milestonesMainHeader = "t_l9 d_l9 d_v d_cq";
export var milestonesSubHeader = "t_mb d_mb d_v";
export var milestonesComponentWrapperRow = "t_rC d_G";
export var milestonesBlockWrapper = "t_rD";
export var milestonesTextBlockWrapper = "t_rF";
export var milestonesComponentHeader = "t_mf d_mf d_v d_cq";
export var milestonesComponentParagraph = "t_mk d_mk d_v";
export var btnWrapper = "t_d1 d_by d_cj";
export var btnWrapperCards = "t_qH d_by d_cc";
export var imageWrapper = "t_rG d_cr d_by";
export var exceptionWeight = "t_rf x_sd";
export var exceptionLineHeight = "t_rH x_sf";