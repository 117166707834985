// extracted by mini-css-extract-plugin
export var lbContainerOuter = "B_t6";
export var lbContainerInner = "B_t7";
export var movingForwards = "B_t8";
export var movingForwardsOther = "B_t9";
export var movingBackwards = "B_vb";
export var movingBackwardsOther = "B_vc";
export var lbImage = "B_vd";
export var lbOtherImage = "B_vf";
export var prevButton = "B_vg";
export var nextButton = "B_vh";
export var closing = "B_vj";
export var appear = "B_vk";