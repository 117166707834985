// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_rg d_gv d_cr";
export var heroHeaderCenter = "s_gw d_gw d_cr d_dv";
export var heroHeaderRight = "s_gx d_gx d_cr d_dw";
export var heroParagraphLeft = "s_rh d_gr d_cv";
export var heroParagraphCenter = "s_gs d_gs d_cv d_dv";
export var heroParagraphRight = "s_gt d_gt d_cv d_dw";
export var heroBtnWrapperLeft = "s_rj d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "s_rk d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "s_rl d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "s_rm d_gq d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "s_rn d_gp d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "s_rp x_rp";
export var heroExceptionNormal = "s_rq x_rq";
export var heroExceptionLarge = "s_rr x_rr";
export var Title1Small = "s_rs x_rs x_rN x_rP";
export var Title1Normal = "s_rt x_rt x_rN x_rQ";
export var Title1Large = "s_rv x_rv x_rN x_rR";
export var BodySmall = "s_rw x_rw x_rN x_r5";
export var BodyNormal = "s_rx x_rx x_rN x_r6";
export var BodyLarge = "s_ry x_ry x_rN x_r7";