// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "y_fQ d_fQ d_by d_bG d_bH";
export var navbarDividedRight = "y_fR d_fR d_by d_bH";
export var menuDesign6 = "y_sW d_fM d_by d_dv d_bN d_bH";
export var menuDesign7 = "y_sX d_fM d_by d_dv d_bN d_bH";
export var menuRight = "y_sY d_fM d_by d_dv d_bN d_bH";
export var menuLeft = "y_sZ d_fM d_by d_dv d_bN d_bH";
export var menuCenter = "y_s0 d_fN d_fM d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "y_p6 d_fN d_fM d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "y_qh d_fP d_fM d_by d_dv d_bN d_bH";
export var isBurger = "y_s1";
export var navbarItem = "y_p7 d_bw";
export var navbarLogoItemWrapper = "y_fY d_fY d_bB d_bN";
export var burgerToggleVisibleOpen = "y_s2 d_gd d_bx d_Y d_br";
export var burgerToggleVisible = "y_s3 d_gd d_bx d_Y d_br";
export var burgerToggle = "y_s4 d_gd d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "y_s5 d_gd d_bx d_Y d_br";
export var burgerInput = "y_s6 d_f2 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "y_s7 d_f3 d_v d_G";
export var burgerLine = "y_s8 d_f1";
export var burgerMenuDesign6 = "y_s9 d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "y_tb d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "y_tc d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "y_td d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "y_tf d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "y_tg d_f9 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "y_th d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var secondary = "y_tj d_bB d_bN";
export var staticBurger = "y_tk";
export var menu = "y_tl";
export var navbarDividedLogo = "y_tm";
export var nav = "y_tn";